import { AppBar, Box, Button, styled, Toolbar,useMediaQuery, Divider, useTheme, BottomNavigation, } from "@mui/material";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Logo from '../../graphics/HorizontalSmall.png'

import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { MainContext } from "contexts/MainContext";
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PersonIcon from '@mui/icons-material/Person';
import ProfilePopover from "./popovers/ProfilePopover";
import SchoolIcon from '@mui/icons-material/School';
import { Span } from "components/Typography";
import { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useWindowSize from "hooks/useWindowSize";


// custom styled components
const DashboardNavbarRoot = styled(AppBar)(() => ({
  zIndex: 11,
  boxShadow: "none",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  width:'100%',
  flex:1,
  
  elevation: 0,

  //backdropFilter: "blur(6px)",
  //backgroundColor: "transparent"
  backgroundColor: "#F3F4F9"
}));
const StyledToolBar = styled(Toolbar)(() => ({
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto"
  }
}));


const DashboardNavbar = () => {



  const navigate = useNavigate();
  const theme = useTheme();
  const {takingQuiz} = useContext(MainContext)
  const downMedium = useMediaQuery(theme => theme.breakpoints.down("md"));
  const [currentPath, setCurrentPath] = useState('')
  const location = useLocation();
  const theWidth =  useWindowSize().width
  
  useEffect(() => {


    setCurrentPath(location.pathname)


  },[location])





  function determineShowTicket(){
    switch(currentPath){
      case '/dashboard/userprofiles':
        return false;
        case '/dashboard/learn':
        return true;
        case '/dashboard/watch':
          return true;

          default:
            return false;
    }
  }



 if(downMedium){
  return(
    
    <BottomNavigation style={{backgroundColor: theme.palette.primary.main, position:'fixed',left:0, bottom:0, width:'100%', zIndex: takingQuiz ? 0 : 1400}}   >
       

          {determineShowTicket() || currentPath === '/dashboard/profile' ?  <BottomNavigationAction onClick={() => navigate('./watch')} icon={  <OndemandVideoIcon style={{fontSize:30}}  /> }>

</BottomNavigationAction>  : null }


{determineShowTicket() || currentPath === '/dashboard/profile' ?  <BottomNavigationAction onClick={() => navigate('./learn')} icon={ <LocalActivityIcon style={{fontSize:30}}  /> }>

</BottomNavigationAction>  : null }

{determineShowTicket() || currentPath === '/dashboard/profile' ?  <BottomNavigationAction onClick={() => navigate('./profile')} icon={ <PersonIcon style={{fontSize:30}}  /> }>

</BottomNavigationAction>  : null }

{currentPath === '/dashboard/manageprofiles' ? <BottomNavigationAction onClick={() => navigate('/dashboard/userprofiles')} icon={ <ExitToAppIcon style={{fontSize:30}}  /> }>

</BottomNavigationAction> : null}

    </BottomNavigation>
  )
 }
 else {
  return <DashboardNavbarRoot  style={{backgroundColor:'white',minWidth: theWidth > 2000 ? 1600 : 0}} position="sticky">
  <StyledToolBar style={{backgroundColor:'white', }}>

    <Span style={{display:'flex', flexDirection:'row'}}>
    <Box style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <img src={Logo} width={225} height={75} alt='LearnWatchGo' />
              </Box>
      

    


    </Span>


    <Box flexGrow={1} ml={1} />



   {determineShowTicket() ?  <Button startIcon={<SchoolIcon />} variant='contained'
      style={{color: "white",marginRight:25}} onClick={() => navigate('./learn')}>
       Take a quiz
      </Button>  : null }

     

    <ProfilePopover showManage={determineShowTicket()} />
  </StyledToolBar>
  <Divider style={{width:'100%', marginTop:15}} />
</DashboardNavbarRoot>;
 }
 
};

export default DashboardNavbar;