import DashboardLayout from 'components/layouts/DashboardLayout';
import { lazy, Suspense } from 'react';
import LoadingScreen from './components/uicomponents/LoadingScreen'
import RivePause from 'components/videos/RivePause';
import FirsrGradeMatchingWordsAlt from 'pages/Matching/FirsrGradeMatchingWordsAlt';





const Loadable = Component => props => <Suspense fallback={<LoadingScreen />}><Component {...props} /></Suspense>

const AddNewUser = Loadable(lazy(() => import('./pages/addNewChildUser/AddNewUserPage'))); 
const AccountSettings = Loadable(lazy(() => import('./pages/accountsettings/AccountSettings'))); 


const CookiePolicy= Loadable(lazy(() => import('./pages/disclaimers/CookiePolicy.js')))

const CoppaPage = Loadable(lazy(() => import('./pages/disclaimers/Coppa')))
const LandingPage = Loadable(lazy(() => import('./pages/landingpagemain/LandingPage.js')));
const DeleteAccount = Loadable(lazy (() => import ('./pages/accountsettings/DeleteAccount')))
const Error = Loadable(lazy(() => import('./pages/disclaimers/404')))
const ForgetPassword = Loadable(lazy(() => import('./pages/authentication/ForgetPassword'))); 
const GuidedAccessMobile = Loadable(lazy(() => import('./pages/guidedaccesspage/GuidedAccessMobile.js')))
const GuidedAccess = Loadable(lazy(() => import('./pages/guidedaccesspage/GuidedAccess.js')))
const GuidedAccessInterior = Loadable(lazy(() => import('./pages/guidedaccesspage/GuidedAccessInterior.js')))
const LearningMain = Loadable(lazy(() => import('./pages/learninghome/LearningMain'))); 
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const MainLargeXL = Loadable(lazy(() => import('./pages/watchmain/WatchMain')));
const OutsideTermsOfService =  Loadable(lazy(() => import('./pages/disclaimers/TermsOfService.js')))
const PrivacyPage = Loadable(lazy(() => import('./pages/disclaimers/Privacy')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));


const UserGrid = Loadable(lazy(() => import('./pages/selectprofile/SelectProfile')));
const ManageProfiles = Loadable(lazy(() => import('./pages/manageprofilespage/ManageProfiles'))); 



function getMainPage(){
  return <MainLargeXL />
}

const routes = [{
  path: '/',

     element: <LandingPage />
    //element: <FirsrGradeMatchingWordsAlt />

},

{
  path: 'login',
   element: <Login />

}, {
  path: 'register',
  element: <Register />

}, 



{
  path: 'forget-password',
  element: 
        <ForgetPassword />
      
},
{
  path:'privacypolicy',
  element: <PrivacyPage />
},
{
  path:'deleteaccount',
  element: <DeleteAccount />
},
{
  path:'termsofservice',
  element: <OutsideTermsOfService />
},
{
  path:'coppapolicy',
  element: <CoppaPage />
},
{
  path:'cookiepolicy',
  element: <CookiePolicy />
},


{
  path:'guidedaccess',
  element: <GuidedAccess />
},

{
  path:'guidedaccessmobile',
  element: <GuidedAccessMobile/>
},






{
  path: 'dashboard',
  element: 
        <DashboardLayout />,
     
  children: [
    

  {
    path:'userprofiles',
    element: <UserGrid />
  },

  {
    path: 'adduser',
    element: <AddNewUser />
  },

  {
    path: 'manageprofiles',
    element: <ManageProfiles />
  },
  {
    path:'accountsettings',
    element: <AccountSettings />
  },



  {
    path: 'watch',
    element: getMainPage()
  }, 

 {
  path:'pause',
  element: <RivePause />
 },
  {
    path: 'learn',
    element:  <LearningMain />
  }, 


  
  
  {
    path: 'account-settings',
    element: <AccountSettings />
  }, 

  {
    path:'guidedaccesshelp',
    element: <GuidedAccessInterior />
  },
  
  
  
 
  {
    path: 'privacy',
    element: <PrivacyPage />
  }]
}, {
  path: '*',
  element: <Error />
}];
export default routes;