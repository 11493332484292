import {Popover } from "@mui/material";
import React from "react"; 

const PopoverLayout = props => {
  const {
    children,
    popoverClose,
    popoverOpen,
    anchorRef,

    minWidth,
    maxWidth
  } = props;
  return <Popover open={popoverOpen} onClose={popoverClose} anchorEl={anchorRef.current} anchorOrigin={{
    horizontal: "center",
    vertical: "bottom"
  }} PaperProps={{
    sx: {
      minWidth: minWidth || 250,
      maxWidth: maxWidth || 375,
      width: "100%",
      padding: "0.5rem 0"
    }
  }}>
   
   

      {children}

 
    </Popover>;
};

export default PopoverLayout;