import React,{Component, createContext} from 'react'
import Axios from 'axios';
// import firebase from 'firebase/app'
// import 'firebase/firestore'
// import 'firebase/auth'
// import 'firebase/functions'
import { firebaseConfig } from "config";
import { initializeApp } from "firebase/app";
import { createUserWithEmailAndPassword, FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth";
import { collection, doc, getFirestore, setDoc, getDoc, getDocs, updateDoc } from "firebase/firestore";
// import { months } from 'pages/dashboards/sampledata';
import { getFunctions, httpsCallable } from "firebase/functions";
import { TrendingUpRounded, TurnedIn } from '@mui/icons-material';
import toast from 'react-hot-toast';


export const MainContext = createContext();

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const functions = getFunctions();


export default class MainContextProvider extends Component{


    constructor(props){
        super(props);
        this.state = {
            isAuthenticated: false,
            isEmailVerified: false,
            isNewUser:false,
            isPremiumUser: false,
            mappedAvatar:"flirt-emoji",
            mainAvatarPath:"https://lysaghtbuck.s3.amazonaws.com/LeoTransparent.png",
            mainProfileSelected:false,
            pinCodeCorrect: false,
            profileId: '',
            takingQuiz:false,
            userUid:'',
            regionCode:'US'

       
        }

        //*************AUTH************************** */
        this.checkIfEmailIsVerified = this.checkIfEmailIsVerified.bind(this);
        this.createNewUserObject = this.createNewUserObject.bind(this);
        this.displayProfileAvatarPath = this.displayProfileAvatarPath.bind(this);
        this.handleToggleTakingQuiz = this.handleToggleTakingQuiz.bind(this);
     
        this.handleSignWithEmailAndPassword = this.handleSignWithEmailAndPassword.bind(this);
        this.logOut = this.logOut.bind(this);
        this.processStripeCancellationDatabase = this.processStripeCancellationDatabase.bind(this);
        this.sendVerificationCode = this.sendVerificationCode.bind(this);
        this.signUpWithEmailAndPassword = this.signUpWithEmailAndPassword.bind(this);
        this.submitVerificationCode = this.submitVerificationCode.bind(this);
        this.updateMainAvatarPath = this.updateMainAvatarPath.bind(this);
        this.updateRegionCode = this.updateRegionCode.bind(this);
        this.updateBetaTestIsPremiumUser = this.updateBetaTestIsPremiumUser.bind(this);
        this.updateIsPremiumUser = this.updateIsPremiumUser.bind(this);
        this.updateSelectedProfileId = this.updateSelectedProfileId.bind(this);
        this.updateStripeSubscriptionId = this.updateStripeSubscriptionId.bind(this);
  //*************END AUTH************************** */



        
    }

    // componentDidMount(){
    //     console.log('HELLO I AM MOUNTED')
    //     const saved = localStorage.getItem('uid');
    //     const savedUid = JSON.parse(saved);

    //     const savedProfile = localStorage.getItem('profileId');
    //     const savedProfileParse = JSON.parse(savedProfile);
       
    //     if(saved  === null){
    //         console.log('saved is null')
    //     }
    //     else {
    //         this.setState({
    //             userUid: savedUid
    //         })
    //     }

    //     if(savedProfileParse === null){

    //     }
    //     else {
    //         this.setState({
    //             profileId: savedProfileParse
    //         })
    //     }
    // }
        
    async checkIfEmailIsVerified(){
        let stateRef = this;
      
        let docRef = doc(db,'EuklesUsers',stateRef.state.userUid);
        let userObject = await getDoc(docRef).then(function(doc) {
                          let emailVerified = doc.data().isEmailVerified;
                  let isPremiumUser = doc.data().isPremiumUser;
                  let finCustomerId = doc.data().finCustomerId;
            return {isEmailVerified: emailVerified, isPremiumUser: isPremiumUser, finCustomerId: finCustomerId, errorEncountered: false};
        }).catch(function(error) {

            return {isEmailVerified: false, isPremiumUser: false, finCustomerId: '', errorEncountered: true};
        })
    //    let userObject = await firebase.firestore().collection('EuklesUsers').doc(stateRef.state.userUid).get().then(function(doc){
           
    //              let emailVerified = doc.data().isEmailVerified;
    //              let isPremiumUser = doc.data().isPremiumUser;
    //              let finCustomerId = doc.data().finCustomerId;
            

    //       return {isEmailVerified: emailVerified, isPremiumUser: isPremiumUser, finCustomerId: finCustomerId, errorEncountered: false};
    //     }).catch(function(error){

    //         return {isEmailVerified: false, isPremiumUser: false, finCustomerId: '', errorEncountered: true};
    //     });

        if(userObject.isEmailVerified){
            stateRef.setState({
                isEmailVerified: true,
                isPremiumUser: userObject.isPremiumUser,
                finCustomerId: userObject.finCustomerId,
                isNewUser: false

            })
         stateRef.handleCheckIfNewUser();
        }
        return userObject;

    
    }

    async createNewUserObject(){

     
        const user = getAuth().currentUser
     
        let userObject = {
         
            completedOnboarding: false,                   
            email: user.email,
            isEmailVerified: false,
            isPremiumUser: false,
            enableTwoFactor: false,
           
            subscribed: false,  
            subscriptionMethod:'none',      
            userUid: user.uid
       

                 
        }


     let docRef = doc(db,'Users', this.state.userUid);
    let creation =  await setDoc(docRef, userObject).then(res => {

        return true;
    }).catch((e) => {
        return false;
    })


    return creation;
    
   
     
    }



 

    async handleSignWithEmailAndPassword(email, password) {
        // e.preventDefault();
        
         let stateRef = this;
      
        
            let userObject = await signInWithEmailAndPassword(auth, email, password).then((result) => {

               // console.log('the user ', result.user)
          //           stateRef.getBudgetPods();
        // stateRef.getManualAssets();
        // stateRef.getManualLiabilities();
                return {authenticated: true, userEmail: email, userUid: result.user.uid ,errorMessage:''}
            }).catch((e) => {
                console.log('error ', e.code);
                let errorMessage = ''
                if(e.code === 'auth/wrong-password'){
                    errorMessage = 'Password is incorrect'
                }
                if(e.code === 'auth/user-not-found'){
                    errorMessage = 'User record does not exist'
                }
                if(e.code === 'auth/too-many-requests'){
                    errorMessage = e.message;
                }
             
              //  showLoginError:false,
               // loginErrorMessage: '',
               return {authenticated: false, userEmail: email, userUid: '', errorMessage: errorMessage}
            })


            if(userObject.authenticated){
                stateRef.setState({
                    canEnter: true,
                    isAuthenticated: true,
                    userEmail: email,
                    userUid: userObject.userUid,
                    isNewUser: false
                    

                })
                //localStorage.setItem('uid', JSON.stringify(userObject.userUid));
           
                
         
                //stateRef.checkIfEmailIsVerified();
                stateRef.checkPremiumStatus(userObject.userUid);
            }
            else if(!userObject.authenticated){
              
                stateRef.setState({
                    showLoginError: true,
                    loginErrorMessage: userObject.errorMessage, 
                    isAuthenticated: false,
                    userUid: ''
                })
            }

            return userObject;

     }

      handleToggleTakingQuiz(value){
        this.setState({
            takingQuiz: value
        })
     }

     async checkPremiumStatus(uid){
        let docRef = doc(db,'Users', uid);
        let check = await getDoc(docRef).then((doc) => {
            return {error: false, isPremiumUser: doc.data().isPremiumUser, regionCode: doc.data().regionCode} 
        }).catch((e) => {
            return  {error: true, isPremiumUser: false}
        })

        if(check.error){
            toast.error('LearnWatchGo has encountered an error')
        }
        else {
           
            this.setState({
                isPremiumUser: check.isPremiumUser,
                regionCode: check.regionCode
            })
        }

     }

     sendVerificationCode() {
        const user = getAuth().currentUser;
         let email = user.email;
         let uid = user.uid;
 
         const customerData = {email, uid};
  
         let configObject = {
           method: 'post',
           url: 'https://us-central1-budgetbettereukles.cloudfunctions.net/sendVerificationCode',
           headers: { 
             'Content-Type': 'application/json', 
             'Accept': 'application/json',
           
       
           },
           data : customerData
         };
       
         Axios(configObject).then(function(response) {
             
             console.log('sent the code')
          
         }).catch(function(error){
          
         })
 
 
     }

     async logOut(){
        let stateRef = this;
    
      let signOutResult =  await signOut().then((result) => {
         
           return true;
        }).catch((e) => {
            return false;
        })

        if(signOutResult){
       
           stateRef.setState({
               isAuthenticated: false,
               userUid: '',
               userEmail: ''
           })
        }
        else if(!signOutResult){
           stateRef.setState({
               isAuthenticated: false,
               userUid: '',
               userEmail: ''
           })
        }
    }

    
    async processStripeCancellationDatabase(){
        this.setState({
            isPremiumUser: false,
          
        })

        let docRef = doc(db,'Users', this.state.userUid);
        await updateDoc(docRef,{
            isPremiumUser: false, subscriptionMethod: 'none', 
        stripeCustomerId:'nil', stripePaymentMethodId:'nil', stripeSubscriptionId:'nil'
        })
        


    }

   async signUpWithEmailAndPassword(email, password){
       let stateRef = this;
     
    
        const auth = getAuth();
       let userObject = await createUserWithEmailAndPassword(auth, email, password).then((result) => {

           return {authenticated: true, userEmail: email, userUid: result.user.uid ,errorMessage:'none', isNewUser: true}
       }).catch((e) => {
       
        
            let message = 'Error creating user'
            if(e.code === 'auth/email-already-in-use'){
                message = 'Email is already in use'
            }
            if(e.code === 'auth/invalid-password'){
                message = 'Invalid password'
            }
         //  showLoginError:false,
          // loginErrorMessage: '',
          return {authenticated: false, userEmail: email, userUid: '', errorMessage: message, isNewUser: false}
       })

       if(userObject.authenticated){
          
       stateRef.setState({
           isAuthenticated: true,
          
           userEmail: email,
           userUid: userObject.userUid,

       })
   //  stateRef.sendVerificationCode(email, userObject.userUid)

       


       }

  



       return userObject;

 

    
   }

   async submitVerificationCode(submittedCode){

  
       let stateRef = this;
    
       const user = getAuth().currentUser;
           let uid = user.uid;

           const customerData = {uid, submittedCode}

           let configObject = {
               method: 'post',
               url: 'https://us-central1-budgetbettereukles.cloudfunctions.net/checkVerificationCode',
               headers: { 
                 'Content-Type': 'application/json', 
                 'Accept': 'application/json',
               
           
               },
               data : customerData
             };

    let  codeResult = await Axios(configObject).then(function(response){

        let canProceed = response.data.accessPermitted;
       

        return{error: false, errorMessage:'', emailVerified: canProceed}
    }).catch(function(error){

        return {error:true, errorMessage:'Incorrect code', emailVerified: false }
    })

    if(!codeResult.error){
        if(codeResult.canProceed){
            stateRef.setState({
                
                isEmailVerified: true
            })

            
           // stateRef.createNewUserObject();
            //updateEmailVerified
            //addToSendGrid
        }
    }

    

         return codeResult;    
       
   }


   updateSelectedProfileId(value,avatar){
       this.setState({
           profileId: value,
           mappedAvatar: avatar
       })
   }

   async updateMainAvatarPath(path, avatarName){
    this.setState({
        mainAvatarPath: path,
      
    })
    let docRef = doc(db,'Users', auth.currentUser.uid);
    await updateDoc(docRef,{avatarPath: path, avatarName: avatarName}).then((res) => {
        return true;
    }).catch((e) => {
        return false;
    })
   
}

async updateRegionCode(code){
    this.setState({
        regionCode: code
      
    })
    let docRef = doc(db,'Users', auth.currentUser.uid);
    await updateDoc(docRef,{regionCode: code}).then((res) => {
        return true;
    }).catch((e) => {
        return false;
    })
   
}

async displayProfileAvatarPath(path){
    this.setState({
        mainAvatarPath: path
      
    })
    // let docRef = doc(db,'Users', auth.currentUser.uid);
    // await updateDoc(docRef,{avatarPath: path}).then((res) => {
    //     return true;
    // }).catch((e) => {
    //     return false;
    // })
   
}
  
    async updateIsPremiumUser(){
        this.setState({
            isPremiumUser: true,
            subscriptionMethod: 'web',
          
        })
        let docRef = doc(db,'Users', auth.currentUser.uid);
        await updateDoc(docRef,{isPremiumUser: true, subscriptionMethod:'web'}).then((res) => {
            return true;
        }).catch((e) => {
            return false;
        })
       
    }

    async updateBetaTestIsPremiumUser(){
        this.setState({
            isPremiumUser: true,
            subscriptionMethod: 'beta',
            isBetaUser:true
          
        })
        let docRef = doc(db,'Users', auth.currentUser.uid);
        await updateDoc(docRef,{isPremiumUser: true, subscriptionMethod:'beta'}).then((res) => {
            return true;
        }).catch((e) => {
            return false;
        })
       
    }



    async updateStripeSubscriptionId(subscriptionId){
        let docRef = doc(db,'Users', auth.currentUser.uid);
        await updateDoc(docRef,{stripeSubscriptionId: subscriptionId}).then((res) => {
            return true;
        }).catch((e) => {
            return false;
        })
    

    }








    
    render(){
        return(
            <MainContext.Provider  value={{...this.state,
            
               //********AUTHORIZATION METHODS******* */
               checkIfEmailIsVerified: this.checkIfEmailIsVerified,        
               createNewUserObject: this.createNewUserObject,      
               displayProfileAvatarPath: this.displayProfileAvatarPath,
                handleToggleTakingQuiz: this.handleToggleTakingQuiz,
               handleSignWithEmailAndPassword: this.handleSignWithEmailAndPassword,
               logOut: this.logOut,
               processStripeCancellationDatabase: this.processStripeCancellationDatabase,
               sendVerificationCode: this.sendVerificationCode,
               signUpWithEmailAndPassword: this.signUpWithEmailAndPassword,
               submitVerificationCode: this.submitVerificationCode,
               updateBetaTestIsPremiumUser: this.updateBetaTestIsPremiumUser,
               updateMainAvatarPath: this.updateMainAvatarPath,
               updateRegionCode: this.updateRegionCode,
               updateIsPremiumUser: this.updateIsPremiumUser,
               updateSelectedProfileId: this.updateSelectedProfileId,
               updateStripeSubscriptionId: this.updateStripeSubscriptionId,
              
               //******AUTHORIZATION METHODS *******/
     
       
              
        
            
            }}>
                   {this.props.children}
            </MainContext.Provider>
           )
    }
}