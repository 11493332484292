
import { doc, getFirestore,getDoc,updateDoc} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {Dialog,DialogContent,Grid, Typography} from '@mui/material';
import LeoCard from './leocard.png'
import { MainContext } from 'contexts/MainContext';
import MainRiveFile from '../../rivefiles/matching.riv'
import React,{useEffect, useState, useContext,useRef} from 'react';
import RightAnswerSound from '../../sounds/alternateright.wav'
import RivePassedTest from 'components/videos/RivePassedTest';
import RivePause from 'components/videos/RivePause';
import toast from 'react-hot-toast';
import { updateSmartPoints,SkipQuizComponent } from "quizutils/QuizHelpers";
import {useRive,Layout,Fit,Alignment} from '@rive-app/react-canvas';
import useSound from 'use-sound';



import All from './audio/all.mp3'
import Go from './audio/go.mp3'
import Like from './audio/like.mp3'
import Too from './audio/to.mp3'
import As from './audio/as.mp3'
import Got from './audio/got.mp3'
import Look from './audio/look.mp3'
import Said from './audio/saidmatch.mp3'

import At from './audio/at.mp3'
import Have from './audio/have.mp3'
import Make from './audio/make.mp3'
import Saw from './audio/saw.mp3'
import Want from './audio/want.mp3'
import Ate from './audio/eightmatch.mp3'
import Here from './audio/here.mp3'
import Must from './audio/must.mp3'
import See from './audio/see.mp3'
import Was from './audio/was.mp3'
import Black from './audio/black.mp3'
import Help from './audio/help.mp3'
import My from './audio/my.mp3'
import The from './audio/the.mp3'
import Went from './audio/went.mp3'
import But from './audio/but.mp3'

import Not from './audio/not.mp3'
import That from './audio/that.mp3'
import Well from './audio/well.mp3'
import Did from './audio/did.mp3'
import Her from './audio/her.mp3'

import They from './audio/they.mp3'
import What from './audio/what.mp3'
import Down from './audio/down.mp3'
import Had from './audio/had.mp3'
import One from './audio/one.mp3'
import Then from './audio/then.mp3'

import Him from './audio/him.mp3'
import Out from './audio/out.mp3'
import There from './audio/there.mp3'
import Yes from './audio/yes.mp3'
import For from './audio/for.mp3'
import Little from './audio/little.mp3'
import Play from './audio/play.mp3'
import This from './audio/this.mp3'
import You from './audio/uletter.mp3'
import Live from './audio/live.mp3'
import Sleep from './audio/sleep.mp3'
import Fly from './audio/flymatch.mp3'
import White from './audio/white.mp3'
import Eight from './audio/eightmatch.mp3'
import Dont from './audio/dont.mp3'
import His from './audio/his.mp3'
import Three from './audio/three.mp3'
import Never from './audio/never.mp3'
import Cold from './audio/cold.mp3'
import In from './audio/inword.mp3'
import Many from './audio/many.mp3'
import Four from './audio/for.mp3'
import Ran from './audio/ran.mp3'
import Two from './audio/to.mp3'
import Big from './audio/big.mp3'
import Small from './audio/small.mp3'
import Eat from './audio/eat.mp3'
import Girl from './audio/girl.mp3'
import Hold from './audio/hold.mp3'
import Has from './audio/has.mp3'
import Brown from './audio/brown.mp3'
import Grow from './audio/grow.mp3'
import On from './audio/on.mp3'
import Green from './audio/green.mp3'
import Under from './audio/under.mp3'
// import Made from './audio/made.mp3'
// import Six from './audio/six.mp3'
// import Walk from './audio/walkmatch.mp3'

import Please from './audio/please.mp3'
import With from './audio/with.mp3'




const db = getFirestore();
const auth = getAuth();


export default function FirsrGradeMatchingWordsAlt({hideAllQuestions, practiceOnly, questionCount, smartPoints,showBrainBreakProp}) {
  //hideAllQuestions, practiceOnly, questionCount, smartPoints,showBrainBreakProp
  // let practiceOnly = true
  // let questionCount = 10
  // let smartPoints = 10
  // let showBrainBreakProp = true;

  // function hideAllQuestions(){

  // }

const [playWith] = useSound(With)
const [playPlease] = useSound(Please)

const [playAll] = useSound(All);
const [playGo] = useSound(Go);
const [playLike] = useSound(Like);
const [playToo] = useSound(Too);
const [playAs] = useSound(As);
const [playGot] = useSound(Got);
const [playLook] = useSound(Look);
const [playSaid] = useSound(Said);

const [playAt] = useSound(At);
const [playHave] = useSound(Have);
const [playMake] = useSound(Make);
const [playSaw] = useSound(Saw);
const [playWant] = useSound(Want);
const [playAte] = useSound(Ate);
const [playHere] = useSound(Here);
const [playMust] = useSound(Must);
const [playSee] = useSound(See);
const [playWas] = useSound(Was);
const [playBlack] = useSound(Black);
const [playHelp] = useSound(Help);
const [playMy] = useSound(My);
const [playThe] = useSound(The);
const [playWent] = useSound(Went);
const [playBut] = useSound(But);

const [playNot] = useSound(Not);
const [playThat] = useSound(That);
const [playWell] = useSound(Well);
const [playDid] = useSound(Did);
const [playHer] = useSound(Her);

const [playThey] = useSound(They);
const [playWhat] = useSound(What);
const [playDown] = useSound(Down);
const [playHad] = useSound(Had);
const [playOne] = useSound(One);
const [playThen] = useSound(Then);

const [playHim] = useSound(Him);
const [playOut] = useSound(Out);
const [playThere] = useSound(There);
const [playYes] = useSound(Yes);
const [playFor] = useSound(For);
const [playLittle] = useSound(Little);
const [playPlay] = useSound(Play);
const [playThis] = useSound(This);
const [playYou] = useSound(You);
const [playLive] = useSound(Live);
const [playSleep] = useSound(Sleep);
const [playFly] = useSound(Fly);
const [playWhite] = useSound(White);
const [playEight] = useSound(Eight);
const [playDont] = useSound(Dont);
const [playHis] = useSound(His);
const [playThree] = useSound(Three);
const [playNever] = useSound(Never);
const [playCold] = useSound(Cold);
const [playIn] = useSound(In);
const [playMany] = useSound(Many);
const [playFour] = useSound(Four);
const [playRan] = useSound(Ran);
const [playTwo] = useSound(Two);
const [playBig] = useSound(Big);
const [playSmall] = useSound(Small);
const [playEat] = useSound(Eat);
const [playGirl] = useSound(Girl);
const [playHold] = useSound(Hold);
const [playHas] = useSound(Has);
const [playBrown] = useSound(Brown);
const [playGrow] = useSound(Grow);
const [playOn] = useSound(On);
const [playGreen] = useSound(Green);
const [playUnder] = useSound(Under);
const [playRight] = useSound(RightAnswerSound);
  


  const [passedTest, setPassedTest] = useState(false);
  const [showMain,setShowMain] = useState(false)
  const [showPause,setShowPause] = useState(showBrainBreakProp);
  const [showSkipQuizLogic, setShowSkipQuizLogic] = useState(false);

  const [canOpenCards,setCanOpenCards] = useState(true)
  const [correctMatchCount,setCorrectMatchCount] = useState(0)
  const [oneOpened,setOneOpened] = useState(false);
  const [oneMatched,setOneMatched] = useState(false);
  const [twoOpened,setTwoOpened] = useState(false);
  const [twoMatched,setTwoMatched] = useState(false);
  const [threeOpened,setThreeOpened] = useState(false);
  const [threeMatched,setThreeMatched] = useState(false);
  const [fourOpened,setFourOpened] = useState(false);
  const [fourMatched,setFourMatched] = useState(false);
  const [fiveOpened,setFiveOpened] = useState(false);
  const [fiveMatched,setFiveMatched] = useState(false);
  const [sixOpened,setSixOpened] = useState(false);
  const [sixMatched,setSixMatched] = useState(false);

  const [oneWord,setOneWord] = useState('Test')
  const [twoWord,setTwoWord] = useState('')
  const [threeWord,setThreeWord] = useState('')
  const [fourWord,setFourWord] = useState('')
  const [fiveWord,setFiveWord] = useState('')
  const [sixWord,setSixWord] = useState('')

  const [cardsOpen,setCardsOpen] = useState(0)
  const [cardsSelected,setCardsSelected] = useState([])


  const answersArrayRef = useRef([])
  const questionsAnsweredCorrectlyRef = useRef(0)
  const questionsAnsweredRef =useRef(0)
  const totalQuestionsRef = useRef(questionCount)
  const {profileId} = useContext(MainContext)

  const { rive, RiveComponent } = useRive({
    src: MainRiveFile,
    artboard:'JustProgress',
    stateMachines: "ProgressBar",
    autoplay: true,
   
    layout: new Layout({
        //fit:Fit.ScaleDown,
        //alignment: Alignment.Center
    })
    
  });


useEffect(() => {
generateQuestions();
},[])

useEffect(() => {

  if(cardsOpen === 2){
    handleEvaluateIfCorrect();
    setCanOpenCards(false);

  }
  else if(cardsOpen < 2){
    setCanOpenCards(true)
  }
},[cardsOpen])
 

  useEffect(() => {
    if(!showBrainBreakProp){
      setShowMain(true);
    }
  },[showBrainBreakProp])

  useEffect(() => {
    if(correctMatchCount === 3){
      rightAnswerRive();
    }
  },[correctMatchCount])


useEffect(() => {
  if (rive) {
    rive.on('statechange', (event) => {
      
      handleRive(event.data[0])
    });
  }
}, [rive]);


function handleRive(stateName){

  if(stateName === 'ClosedPressed'){
      setShowSkipQuizLogic(true);
    }
  }



  function generateQuestions(){



    try{



   
      let allWords = ['all','go','like','please','too','as','got','look','said',
      'two','at','have','make','saw',
      'want','ate','here','must','see','was','black','help','my','the','went',
      'but','his','not','that',
      'well','did','her','on','they',
      'what','down','had','one','then','with','eat','him','out','there','yes',
      'little','play','this','you','live','all','sleep','fly','white','eight',
      'his','three','never','cold',
    'in','many','four','ran','two','big','small','eat','girl','hold','has','brown',
    'grow','on','green','under']
  

      allWords = allWords.sort(() => Math.random() - 0.5);
  

  const words = allWords.slice(0, 3);
  
  let positions = [1, 2, 3, 4, 5, 6];

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
  

  positions = shuffleArray(positions);
  
  
  

  
  const result = [];
  


answersArrayRef.current = []
  
let localArray = []

  words.forEach(word => {
 
    const firstPosition = positions.pop();
    const secondPosition = positions.pop();
  
    result.push({
      word,
      firstPosition,
      secondPosition
    });
    if(firstPosition === 1){
      setOneWord(word)

    }
    if(firstPosition === 2){
      setTwoWord(word)

    }
    if(firstPosition === 3){
      setThreeWord(word)

    }
    if(firstPosition === 4){
      setFourWord(word)

    }
    if(firstPosition === 5){
      setFiveWord(word)

    }
    if(firstPosition === 6){
      setSixWord(word)
  
    }
  
  
    if(secondPosition === 1){
      setOneWord(word)

    }
    if(secondPosition === 2){
      setTwoWord(word)

    }
    if(secondPosition === 3){
      setThreeWord(word)

    }
    if(secondPosition === 4){
      setFourWord(word)

    }
    if(secondPosition === 5){
      setFiveWord(word)
 
    }
    if(secondPosition === 6){
      setSixWord(word)
  
    }
  
    let subArray =[firstPosition,secondPosition]
    localArray.push(subArray)

  });



  answersArrayRef.current = localArray


    }catch(e){
      console.log(e)
    }




  

}

function handleCardPress(position,word){
  if(canOpenCards){

  
  playClue(word)
  if(position === 1){

    setCardsOpen(prevCount => prevCount + 1)
    setOneOpened(true);
    setCardsSelected(prevArray => [...prevArray,1])
    
  }

  else if(position === 2){
    setCardsOpen(prevCount => prevCount + 1)
    setTwoOpened(true);
    setCardsSelected(prevArray => [...prevArray,2])
  }

  else if(position === 3){
    setCardsOpen(prevCount => prevCount + 1)
    setThreeOpened(true);
    setCardsSelected(prevArray => [...prevArray,3])
  }

  else if(position === 4){
    setCardsOpen(prevCount => prevCount + 1)
    setFourOpened(true);
    setCardsSelected(prevArray => [...prevArray,4])
  }

  else if(position === 5){
    setCardsOpen(prevCount => prevCount + 1)
    setFiveOpened(true);
    setCardsSelected(prevArray => [...prevArray,5])
  }


  else if(position === 6){
    setCardsOpen(prevCount => prevCount + 1)
    setSixOpened(true);
    setCardsSelected(prevArray => [...prevArray,6])
  }
}
}

function handleEvaluateIfCorrect(){
 
  let firstAnswers = answersArrayRef.current[0]
  let secondAnswers = answersArrayRef.current[1]
  let thirdAnswers = answersArrayRef.current[2]



  let isUserAnswerOneInFirstAnswers = firstAnswers.findIndex(obj => obj === cardsSelected[0])
    
  if(isUserAnswerOneInFirstAnswers !== -1 ){
    let isUserAnswerTwoInFirstAnswers = firstAnswers.findIndex(obj => obj === cardsSelected[1])

    if(isUserAnswerTwoInFirstAnswers !== -1){


      setTimeout(() => {
        handleMatches(cardsSelected[0])
        handleMatches(cardsSelected[1])
        setCardsSelected([])
        setCardsOpen(0)
        setCorrectMatchCount(prevCount => prevCount + 1)
      }, 1000);

    }
    else {
  
      setTimeout(() => {
        handleNonMatches(cardsSelected[0])
        handleNonMatches(cardsSelected[1])
        setCardsSelected([])
        setCardsOpen(0)
      
      }, 2000);
    
     
   
    }
  }

  let isUserAnswerOneInSecondAnswers = secondAnswers.findIndex(obj => obj === cardsSelected[0])
  if(isUserAnswerOneInSecondAnswers !== -1 ){
    let isUserAnswerTwoInSecondAnswers = secondAnswers.findIndex(obj => obj === cardsSelected[1])

    if(isUserAnswerTwoInSecondAnswers !== -1){
      setTimeout(() => {
        handleMatches(cardsSelected[0])
        handleMatches(cardsSelected[1])
        setCardsSelected([])
        setCardsOpen(0)
        setCorrectMatchCount(prevCount => prevCount + 1)
      }, 1000);

    }
    else {
     

      setTimeout(() => {
        handleNonMatches(cardsSelected[0])
        handleNonMatches(cardsSelected[1])
        setCardsSelected([])
        setCardsOpen(0)
      }, 2000);
    
    }
  }


  let isUserAnswerOneInThirdAnswers = thirdAnswers.findIndex(obj => obj === cardsSelected[0])
     
  if(isUserAnswerOneInThirdAnswers !== -1 ){
    let isUserAnswerTwoInThirdAnswers = thirdAnswers.findIndex(obj => obj === cardsSelected[1])

    if(isUserAnswerTwoInThirdAnswers !== -1){
      setTimeout(() => {
        handleMatches(cardsSelected[0])
        handleMatches(cardsSelected[1])
        setCardsSelected([])
        setCardsOpen(0)
        setCorrectMatchCount(prevCount => prevCount + 1)
      }, 1000);
      
    }
    else {
    
    setTimeout(() => {
      handleNonMatches(cardsSelected[0])
      handleNonMatches(cardsSelected[1])
      setCardsSelected([])
      setCardsOpen(0)
    }, 2000);
  
    }
  }
}

function handleHidePause(){
  setShowPause(false);
  setShowMain(true);
}
function handleNonMatches(number){
  if(number === 1){
    setOneOpened(false);
  }

  else if(number === 2){
    setTwoOpened(false);
  }

  else if(number === 3){
    setThreeOpened(false);
  }

  else if(number === 4){
    setFourOpened(false);
  }

  else if(number === 5){
    setFiveOpened(false);
  }

  else if(number === 6){
    setSixOpened(false);
  }


  



}

function handleMatches(number){
  if(number === 1){
   setOneMatched(true)
  }

  else if(number === 2){
    setTwoMatched(true)
  }

  else if(number === 3){
    setThreeMatched(true)
  }

  else if(number === 4){
    setFourMatched(true)
  }

  else if(number === 5){
    setFiveMatched(true)
  }

  else if(number === 6){
    setSixMatched(true)
  }


  



}

function playClue(word) {
    
  //please
  try{
    switch (word) {

      case 'with':
        return playWith();
      case 'please':
        return playPlease();
      case 'all':
        return playAll();
      case 'go':
        return playGo();
      case 'like':
        return playLike();
      case 'too':
        return playToo();
      case 'as':
        return playAs();
      case 'got':
        return playGot();
      case 'look':
        return playLook();
      case 'said':
        return playSaid();
      case 'at':
        return playAt();
      case 'have':
        return playHave();
      case 'make':
        return playMake();
      case 'saw':
        return playSaw();
      case 'want':
        return playWant();
      case 'ate':
        return playAte();
      case 'here':
        return playHere();
      case 'must':
        return playMust();
      case 'see':
        return playSee();
      case 'was':
        return playWas();
      case 'black':
        return playBlack();
      case 'help':
        return playHelp();
      case 'my':
        return playMy();
      case 'the':
        return playThe();
      case 'went':
        return playWent();
      case 'but':
        return playBut();
      case 'not':
        return playNot();
      case 'that':
        return playThat();
      case 'well':
        return playWell();
      case 'did':
        return playDid();
      case 'her':
        return playHer();
      case 'they':
        return playThey();
      case 'what':
        return playWhat();
      case 'down':
        return playDown();
      case 'had':
        return playHad();
      case 'one':
        return playOne();
      case 'then':
        return playThen();
      case 'him':
        return playHim();
      case 'out':
        return playOut();
      case 'there':
        return playThere();
      case 'yes':
        return playYes();
      case 'for':
        return playFor();
      case 'little':
        return playLittle();
      case 'play':
        return playPlay();
      case 'this':
        return playThis();
      case 'you':
        return playYou();
      case 'live':
        return playLive();
      case 'sleep':
        return playSleep();
      case 'fly':
        return playFly();
      case 'white':
        return playWhite();
      case 'eight':
        return playEight();
      case 'dont':
        return playDont();
      case 'his':
        return playHis();
      case 'three':
        return playThree();
      case 'never':
        return playNever();
      case 'cold':
        return playCold();
      case 'in':
        return playIn();
      case 'many':
        return playMany();
      case 'four':
        return playFour();
      case 'ran':
        return playRan();
      case 'two':
        return playTwo();
      case 'big':
        return playBig();
      case 'small':
        return playSmall();
      case 'eat':
        return playEat();
      case 'girl':
        return playGirl();
      case 'hold':
        return playHold();
      case 'has':
        return playHas();
      case 'brown':
        return playBrown();
      case 'grow':
        return playGrow();
      case 'on':
        return playOn();
      case 'green':
        return playGreen();
      case 'under':
        return playUnder();
      default:
        return; // Return nothing if word doesn't match any case
    }
  }catch(e){
    console.log(e)
  }

}

function rightAnswerRive(){
  playRight();
    questionsAnsweredCorrectlyRef.current = questionsAnsweredCorrectlyRef.current + 1
    questionsAnsweredRef.current =  questionsAnsweredRef.current + 1
    let answeredCorrectlyString = String(questionsAnsweredCorrectlyRef.current)


    let inputs = rive.stateMachineInputs("ProgressBar")
    const answerCorrect = inputs.find(i => i.name === "Answer-Correct")
    const answersNum = inputs.find(i => i.name === "AnswersNum")
    rive.setTextRunValue('CorrectRun',answeredCorrectlyString)
   
    answerCorrect.fire();
   

    
    let progress = questionsAnsweredRef.current/totalQuestionsRef.current
    progress = (progress * 100).toFixed(1)
    progress = Math.round(progress)

    answersNum.value = progress




    if(questionsAnsweredRef.current === totalQuestionsRef.current){
    
      setPassedTest(true);
      setShowMain(false);
      let updatedSmartPoints = practiceOnly ?  parseFloat(questionsAnsweredCorrectlyRef.current * 20) :  parseFloat(questionsAnsweredCorrectlyRef.current * 10)
      updateSmartPoints(updatedSmartPoints,profileId);
      //updateSPearned(questionsAnsweredCorrectlyRef.current);
    }
    else {
      setCorrectMatchCount(0)
      setOneMatched(false);
      setTwoMatched(false);
      setThreeMatched(false);
      setFourMatched(false);
      setFiveMatched(false);
      setSixMatched(false);
      setOneOpened(false);
      setTwoOpened(false);
      setThreeOpened(false);
      setFourOpened(false);
      setFiveOpened(false);
      setSixOpened(false);
      generateQuestions();
    }


}


function toggleSkipQuiz(value){
  if(value){
   skipQuiz();
  }
  else{
   setShowSkipQuizLogic(false);
  }
}



async function skipQuiz(){


  let docRef = doc(db,'UsersData', auth.currentUser.uid, 'Profiles', profileId);
  let count = await getDoc(docRef).then((doc) => {
    let theCount = doc.data().playCount;
    let currentSmartPoints = doc.data().smartPoints;

    return {playCount: theCount, smartPoints: currentSmartPoints}
  }).catch((e) => {
    return false;
  })
  



  if(count !== false){
    let updatedSmartPoints = parseFloat(count.smartPoints) - parseFloat(1000);

    let nextRef = doc(db,'UsersData', auth.currentUser.uid, 'Profiles', profileId);
    await updateDoc(nextRef, {smartPoints:updatedSmartPoints})
  }
 




  toast.success('Successfully skipped a quiz for 1,000 SmartPoints')


 handleGoBackToMainVideos();
}


async function handleGoBackToMainVideos(){
 
  if(!practiceOnly){
    let docRef = doc(db,'UsersData', auth.currentUser.uid, 'Profiles', profileId);
    await updateDoc(docRef,{playCount:1}).then(res => {
      return true;
    })
    hideAllQuestions('Alphabet','Sight Words');
  }
  else {
    hideAllQuestions();
  }


}



  return (
    <Dialog style={{overflowX:'hidden'}} fullScreen open={true}>
      {showPause ? <DialogContent> <div style={{height:'100%',width:'100%',}}><RivePause handleHide={handleHidePause} /></div></DialogContent> : null}
      {passedTest ? <RivePassedTest handleGoBackToMainVideos={handleGoBackToMainVideos} /> : null}
      {showSkipQuizLogic ? <SkipQuizComponent smartPoints={smartPoints} toggleSkipQuiz={toggleSkipQuiz}  /> : null}

{showMain ?     <DialogContent>
    <div style={{height:'100%',width:'100%',}}>
 

     <Grid container direction='row' justifyContent='center'>
        <Grid item>

        <div style={{height:200, width:400}}>
        <RiveComponent /> 
        </div>
      
        </Grid>
        </Grid>


      <Grid container direction = 'row' justifyContent='center'>

      <Grid item  ml={5} mr={8}>
        {oneOpened ? <div style={{height:232,width:182,border:oneMatched ?'transparent' : '4px solid #54a1FF',borderRadius:5,alignContent:'center',alignItems:'center',textAlign:'center' }}><Typography variant="h5" style={{fontWeight:'bold',color:oneMatched ? 'transparent' : '#54a1FF'}}>{oneWord}</Typography></div> : 
        <div onClick={() => handleCardPress(1,oneWord) }> <img src={LeoCard} style={{height:232, width:182}} /></div>}
     
        </Grid>

        <Grid item  ml={5} mr={8}>
        {twoOpened ? <div style={{height:232,width:182,border:twoMatched ?'transparent' : '4px solid #54a1FF',borderRadius:5,alignContent:'center',alignItems:'center',textAlign:'center' }}><Typography variant="h5" style={{fontWeight:'bold',color:twoMatched ? 'transparent' : '#54a1FF'}}>{twoWord}</Typography></div> : 
        <div onClick={() => handleCardPress(2,twoWord) }> <img src={LeoCard} style={{height:232, width:182}} /></div>}
     
        </Grid>

        <Grid item  ml={5} mr={8}>
        {threeOpened ? <div style={{height:232,width:182,border:threeMatched ?'transparent' : '4px solid #54a1FF',borderRadius:5,alignContent:'center',alignItems:'center',textAlign:'center' }}><Typography variant="h5" style={{fontWeight:'bold',color:threeMatched ? 'transparent' : '#54a1FF'}}>{threeWord}</Typography></div> : 
        <div onClick={() => handleCardPress(3,threeWord) }> <img src={LeoCard} style={{height:232, width:182}} /></div>}
     
        </Grid>
      </Grid>

      <Grid container direction = 'row' justifyContent='center' mt={5}>

      <Grid item  ml={5} mr={8}>
      {fourOpened ? <div style={{height:232,width:182,border:fourMatched ?'transparent' : '4px solid #54a1FF',borderRadius:5,alignContent:'center',alignItems:'center',textAlign:'center' }}><Typography variant="h5" style={{fontWeight:'bold',color:fourMatched ? 'transparent' : '#54a1FF'}}>{fourWord}</Typography></div> : 
        <div onClick={() => handleCardPress(4,fourWord) }> <img src={LeoCard} style={{height:232, width:182}} /></div>}
        </Grid>
        <Grid item  ml={5} mr={8}>
        {fiveOpened ? <div style={{height:232,width:182,border:fiveMatched ?'transparent' : '4px solid #54a1FF',borderRadius:5,alignContent:'center',alignItems:'center',textAlign:'center' }}><Typography variant="h5" style={{fontWeight:'bold',color:fiveMatched ? 'transparent' : '#54a1FF'}}>{fiveWord}</Typography></div> : 
        <div onClick={() => handleCardPress(5,fiveWord) }> <img src={LeoCard} style={{height:232, width:182}} /></div>}
        </Grid>

        <Grid item  ml={5} mr={8}>
        {sixOpened ? <div style={{height:232,width:182,border:sixMatched ?'transparent' : '4px solid #54a1FF',borderRadius:5,alignContent:'center',alignItems:'center',textAlign:'center' }}><Typography variant="h5" style={{fontWeight:'bold',color:sixMatched ? 'transparent' : '#54a1FF'}}>{sixWord}</Typography></div> : 
        <div onClick={() => handleCardPress(6,sixWord) }> <img src={LeoCard} style={{height:232, width:182}} /></div>}
        </Grid>

</Grid>



 </div>
 
 </DialogContent> : null}

 </Dialog>
   )
}
