import { Box, styled } from "@mui/material";
import { Fragment } from "react";
import { Outlet } from "react-router";
import DashboardNavbar from "./DashboardNavbar";


const Wrapper = styled(Box)(({
  theme,
  show
}) => ({
  width: `calc(100% - ${show ? "320px" : "80px"})`,
  paddingLeft: "3rem",
  paddingRight: "3rem",
  transition: "all 0.3s",
  marginLeft: show ? 320 : 80,
  [theme.breakpoints.down(1200)]: {
    width: "100%",
    marginLeft: 0,
    paddingLeft: "2rem",
    paddingRight: "2rem"
  }
}));
const InnerWrapper = styled(Box)(({
  theme
}) => ({
  [theme.breakpoints.up("lg")]: {
    maxWidth: 1400,
    margin: "auto"
  }
}));

const DashboardLayout = ({children}) => {



  return <Fragment>
    

      <Wrapper >
        <InnerWrapper>
          <DashboardNavbar  />
          {children || <Outlet />}
        </InnerWrapper>
      </Wrapper>
    </Fragment>;
};

export default DashboardLayout;