

import './styles.css'
import { CssBaseline, ThemeProvider } from "@mui/material";
import {Elements} from '@stripe/react-stripe-js';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {loadStripe} from '@stripe/stripe-js';
import MainContextProvider from "contexts/MainContext";
import routes from "./routes";

import { StyledEngineProvider } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";

import { useRoutes } from "react-router-dom";

import { lwgTheme } from './theme/lwgtheme.js'


//March 19th notes:  Make sure mobile version avatars are correct, update videos to be ordered by date
//revisit back up function when retrieving videos
//revisit youtube redirect
//auto-channel analyzer
//add in when michael gets a channel add
//change michael to first
//add in uid to user sign up
//recreate web header with rive


//fix matching
//fix 2 times 1 bottom







//loadStripe.setLoadParameters({advancedFraudSignals: false})


const stripePromise = loadStripe('pk_live_51Kc7idL3MkPapoSPpFMII1KQYnGdjYDmfCfX0V8j8ZVHVbJqhaM7p4P3FG3oAJfAoc6DpirDAof4z26m2eMwii7B00wyPbLVJY',{advancedFraudSignals: false})
//const stripePromise = loadStripe('pk_test_51Kc7idL3MkPapoSPwwrayD16OrpeMlPy3dG9PWHSNHGzNzPxt9wD62CZJypd2s1ArWaViX5R3rB3gw4cEGXXxQGn00a7W4a9FN')



//telling time









const App = () => {
  const allPages = useRoutes(routes);

  const appTheme = lwgTheme();

  const toasterOptions = {style: {fontWeight: 500,fontFamily: "'Montserrat', sans-serif"}}
  return <HelmetProvider>

<Helmet>
      <title>LearnWatchGo</title>
      <meta name='description' content='LearnWatchGo provides parental controls for your child while he or she is watching YouTube. An alternative to youtube kids, LearnWatchGo allows your child to watch YouTube but only the channels that you select.  That way you have parental control over the youtube videos that are displayed.
      The first step is to creat a profile for your child and select the youtube channels that your child can watch.  Any channel available on youtube is available on LearnWatchGo and you can remove channels from a profile at any time.  You can filter out certain videos, there is no commenting, and no endless video rabbit hole.
      Review youtube video transcripts.  Each transcript is scanned and highlighted for profanity and questionable content.  You can also review in real-time what your child is watching on youtube.  Even better, you can require your child to pass a quiz
      in between youtube videos in order to watch another video.  '>
      </meta>
      <meta name='keywords' content='LearnWatchGo, YouTube, Parental Controls, Parental controls for youtube, youtube with quizzes, youtube with brain breaks, kids youtube, youtubekids, safevision'/>
    </Helmet>

 <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <MainContextProvider>
        <Elements stripe={stripePromise}>
  
          <CssBaseline />
          <Toaster toastOptions={toasterOptions} />
          {allPages}
     
        </Elements>
        </MainContextProvider>
   
      
      </ThemeProvider>
    </StyledEngineProvider>
    </HelmetProvider> 
};

export default App;