import React, { useEffect,useState } from 'react'
import Rive from '@rive-app/react-canvas'
import {useRive,useResizeCanvas,useStateMachineInput,Layout,Fit,Alignment} from '@rive-app/react-canvas';
import RiveFileMain from './brainbreaklandscape.riv'
import useSound from 'use-sound';
import PauseVideo from 'components/videos/PauseVideo';

import PassedTest from 'components/videos/PassedTest';
import SoundMain from './BellSound.m4a'
import Sound from 'react-sound'
import { Dialog,DialogContent } from '@mui/material';

export default function RivePause({handleHide}) {

    const { rive, RiveComponent } = useRive({
        src: RiveFileMain,
        stateMachines: "BrainBreak",
        autoplay: false,
        onStateChange: (event) => {
       
            if(event.data[0] === 'exit'){
               handleHide();
            }
          },
        layout: new Layout({
            fit:Fit.Contain,
            alignment: Alignment.Center
        })
        
      });

      const [isPlaying,setIsPlaying] = useState(false)
      const [audio] = useState(new Audio(SoundMain))

      useEffect(() => {
        if (isPlaying) {
          audio.play();
        } else {
          audio.pause();
          audio.currentTime = 0; // Reset the audio to the beginning
        }
    
        // Cleanup function to pause and reset the audio when the component unmounts
        return () => {
          audio.pause();
          audio.currentTime = 0;
        };
      }, [audio, isPlaying]);

     function playSong(){
   
     setIsPlaying(true)
     }

      useEffect(() => {
      
        if(rive !== null){
          if(rive.readyForPlaying){
            rive.play();
            playSong();

          }
        }

      },[rive])
  




    return (
  
    
      <div style={{height:'100%',width:'100%'}}>
   
       
       <RiveComponent       /> 
   
   
     
   
   </div>
 
     )
  


}
