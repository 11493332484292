// Your web app's Firebase configuration
// export const firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APPID
// };


export const firebaseConfig = {
  apiKey: "AIzaSyDpQX6YoVejlMt7JbscMXmrxbaG9bSEr78",
  authDomain: "youlearntube.firebaseapp.com",
  projectId: "youlearntube",
  storageBucket: "youlearntube.appspot.com",
  messagingSenderId: "325143128571",
  appId: "1:325143128571:web:20671304523edfca13435e",
  measurementId: "G-WRSQR6N57L"
};




export const auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID
};