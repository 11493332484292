

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { StrictMode } from "react";




ReactDOM.render(<StrictMode><BrowserRouter>
              <App />
            </BrowserRouter>
  </StrictMode>, document.getElementById("root"));