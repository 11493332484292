
import { Button,Typography,useTheme } from '@mui/material';
import { getAuth } from "firebase/auth";
import numeral from 'numeral';
import { Span,H4 } from 'components/Typography';
import { doc, getFirestore,updateDoc,increment} from "firebase/firestore";
import { uploadError } from 'utils/UserMethods';


export function randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  export const SkipQuizComponent = ({smartPoints,toggleSkipQuiz}) => {
    const theme = useTheme();
    return(
      <div style={{display:'flex', padding:25, borderRadius:5, backgroundColor: theme.palette.primary.navy, flexDirection:'column', position:'absolute', top:25,zIndex:5400,right:0 }}>
          <H4 style={{color: 'white', fontWeight:'bold'}}>Do you want to skip this quiz for 1,000 Smart Tickets?</H4>
              <Typography variant='caption' style={{fontWeight:'bold', textAlign:'center', color:'white'}}>Your Balance: <Span style={{fontWeight:'bold', color: theme.palette.primary.main}}>
                        {numeral(smartPoints).format("0,00")}
                                  </Span>
                    </Typography>
            
                  <Button variant='outlined' onClick={() => toggleSkipQuiz(false)} style={{marginTop:25, color:'white'}}>Cancel</Button>
                     <Button style={{marginTop:15}} disabled={smartPoints >= parseInt(1000)  ?  false : true} onClick={() => toggleSkipQuiz(true)} variant='contained'>Yes, skip  </Button>
     
        </div> 
    )



  }


  export async function updateSmartPoints(points,profileId){

    try{
      const auth = getAuth();
      const db = getFirestore();
      let docRef = doc(db,'UsersData', auth.currentUser.uid, 'Profiles', profileId);
     const userRecord = await updateDoc(docRef,{
      smartPoints: increment(points)
     }).then(() => {
      return true;
     }).catch(() => {
      return false;
     })
     return userRecord
    }catch(e){
      uploadError(e.message,'QuizHelpers,updateSmartPoints')
    }

  
  }