import { Box, CircularProgress } from "@mui/material";

const LoadingScreen = () => {

  return(
    <Box sx={{ display: "flex", alignItems: "center",justifyContent: "center",height: "100vh"}}>
        <CircularProgress />
      </Box>

  )
   
};

export default LoadingScreen;