

export const primary = {
  100: "#cec4fd",
  200: "#ab9cfd",
  300: "#8373ff",
  400: "#5d54ff",
  500: "#1935fe",
  mainpurple: "#5d54ff",
 main: "#54a1FF",
  light: "#ab9cfd",
  dark: "#001cda",
  red: "#d7332b",
  //red: "#ff5d54",
  purple: "#b254ff",
  yellow: "#f6ff54",
  lightblue: "#54a1FF",
  pinkish: "#FF54F6",
  green: "#00C787",
  charcoal: "#333B45",
  blackcoral: "#545E70",
  navy:'#545370',

  alternatemain: '#f7f9fc',
  alternatedark: '#edf1f7',

  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light',

 
  webeeDark: '#009357',
  webeeDefault: '#fff',

  backgroundPaper: '#fff',
  backgroundDefault: '#fff',
  backgroundLevel2: '#f5f5f5',
  backgroundLevel1: '#fff', 
  divider: 'rgba(0, 0, 0, 0.12)',
}

  // main: "#3f51b5",
  // light: "#757de8",






export const secondary = {
  100: "#F9F9F9",
  200: "#ECEFF5",
  300: "#E5EAF2",
  // outline or border
  400: "#94A4C4",
  // text muted
  500: "#1d2438",
  // main text
  main: "#1d2438",
  // main text
  light: "#F9F9F9",
  red: "#FF6B93",
  purple: "#A798FF",
  yellow: "#FF9777",
green: "#00C787"
};



export const error = {
  main: "#FD396D"
};
export const success = {
  main: "#2CC5BD",
  lighter: "#87d9d3",
  evenlighter: "#a8e3dd",
  textlight: "7EE4E0"
};
export const warning = {
  main: "#FFE91F",
  dark: "#FFD600"
};
export const info = {
  main: "#A798FF"
};
export const text = {
  primary: secondary[500],
  secondary: secondary[400],
  disabled: secondary[300]
};