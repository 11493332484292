
import { collection,getFirestore,addDoc} from "firebase/firestore";
import { getAuth } from "firebase/auth";



export async function uploadError(errorMessage,details){
   
    const uid =  getAuth().currentUser.uid;
    const db = getFirestore();
    let docRef = collection(db,'UpdatedWebErrors')
    let rep = await addDoc(docRef,{errorMessage:errorMessage,details:details,userUid:uid}).then(res => {
      return true;
    }).catch(e => {
      return false;
    })

  return rep
  }