import { Box, ButtonBase, Divider, styled, } from "@mui/material";
import FlexBox from '../../uicomponents/FlexBox'
import { Fragment, useRef, useState,} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import PopoverLayout from "./PopoverLayout";
import {Small} from "components/Typography";
import { useNavigate } from "react-router-dom";



const StyledSmall = styled(Small)(({
  theme
}) => ({
  display: "block",
  padding: "5px 1rem",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.mode === "light" ? theme.palette.secondary.light : theme.palette.divider
  }
}));

const ProfilePopover = () => {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleMenuItem = path => {
    navigate(path);
    setOpen(false);
  };





  return <Fragment>
      <ButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>
      <MenuIcon sx={{width:35, height:35}} />
      </ButtonBase>
    

      <PopoverLayout hiddenViewButton maxWidth={230} minWidth={200} popoverOpen={open} anchorRef={anchorRef} popoverClose={() => setOpen(false)} 
      title={<FlexBox alignItems="center"> </FlexBox>}>
     

        
        <Box pt={1}>
      
       
          <StyledSmall style={{fontSize:16}} onClick={() => handleMenuItem("/dashboard/userprofiles")}>
            Select Profile
          </StyledSmall>
          <StyledSmall style={{fontSize:16}}  onClick={() => handleMenuItem("/dashboard/manageprofiles")}>
           Parental Controls
          </StyledSmall>

          <StyledSmall style={{fontSize:16}}   onClick={() => handleMenuItem("/dashboard/accountsettings")}>
           Account Settings
          </StyledSmall>
          <StyledSmall style={{fontSize:16}}   onClick={() => handleMenuItem("/dashboard/guidedaccesshelp")}>
           Guided Access
          </StyledSmall>



          <Divider sx={{my: 1}} />

          <StyledSmall style={{fontSize:16}} onClick={() => {navigate('/')}}>
            Sign Out
          </StyledSmall>
        </Box>
      </PopoverLayout>
    </Fragment>;
};

export default ProfilePopover;